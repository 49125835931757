import { ActionIcon } from '@mantine/core';
import { Icon } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { OnboardingRequestDetailModel } from 'api/models';
import { Information, InformationSection } from 'components/Information/Information';
type Props = {
    request: OnboardingRequestDetailModel;
    onEditClick?: () => void;
};

type InformationItemProps = {
    icon?: string;
    title: string;
    children: React.ReactNode;
    className?: string;
};

const InformationItem = ({ icon, title, children, className = '' }: InformationItemProps) => (
    <div className={`flex ${icon ? '' : 'ml-5'} ${className}`}>
        {icon && <Icon className="text-icon-default mr-1" icon={icon} />}
        <InformationSection className="flex-grow" title={title}>
            <div className="text-text-light">{children}</div>
        </InformationSection>
    </div>
);

export const CompanyInformation = ({ request, onEditClick }: Props) => {
    const { t } = useTranslation();

    if (!request) {
        return null;
    }

    const company = request.companyData;

    return (
        <Information className="p-md">
            <div className="flex justify-between">
                {company.companyName && (
                    <InformationSection title={company.companyName}>
                        {company.additionalCompanyName && (
                            <p className="text-text-light text-sm">{company.additionalCompanyName}</p>
                        )}
                    </InformationSection>
                )}
                <div className="ml-auto">
                    {onEditClick && (
                        <ActionIcon onClick={onEditClick}>
                            <Icon className="text-text-default" icon="edit" />
                        </ActionIcon>
                    )}
                </div>
            </div>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                {company.mainAddress && (
                    <InformationItem icon="location_on" title={t('address')}>
                        <p>
                            {company.mainAddress?.street} {company.mainAddress?.houseNumber}
                        </p>
                        <p>
                            {company.mainAddress?.zipCode} {company.mainAddress?.city}
                        </p>
                        <p>{company.mainAddress?.countryConfiguration?.name}</p>
                    </InformationItem>
                )}
                {company.vatNr && (
                    <InformationItem icon="credit_card" title={t('uidNumber')}>
                        <p>{company.vatNr}</p>
                    </InformationItem>
                )}
                {request?.administrators && request.administrators[0]?.model.email && (
                    <InformationItem icon="person" title={t('administrator')}>
                        <div className="flex flex-col gap-1">
                            <div className="text-text-light">
                                <p>{request.administrators[0]?.model.email}</p>
                            </div>
                            {request?.signers && request.signers[0]?.model.email && (
                                <div className="text-text-light">
                                    <div className="text-text-default text-base">{t('contractSignatoryContent')}</div>
                                    <p>{request.signers[0]?.model.email}</p>
                                </div>
                            )}
                        </div>
                    </InformationItem>
                )}
                {company.email && (
                    <InformationItem icon="call" title={t('generalOfficeEmail')}>
                        <div className="flex flex-col gap-1">
                            <div className="text-text-light">
                                <p>{company.email}</p>
                            </div>
                            {company.phone && (
                                <div className="text-text-light">
                                    <div className="text-text-default text-base">{t('phoneNumber')}</div>
                                    <p>{company.phone}</p>
                                </div>
                            )}
                            {company.mobilePhone && (
                                <div className="text-text-light">
                                    <div className="text-text-default text-base">{t('mobilePhone')}</div>
                                    <p>{company.mobilePhone}</p>
                                </div>
                            )}
                        </div>
                    </InformationItem>
                )}
                {company.taxNr && (
                    <InformationItem title={t('taxNr', 'Tax Number')}>
                        <p>{company.taxNr}</p>
                    </InformationItem>
                )}
                {company.industrialSectorIdentifier && (
                    <InformationItem title={t('industrialSectorIdentifier', 'Industrial Sector Identifier')}>
                        <p>{company.industrialSectorIdentifier}</p>
                    </InformationItem>
                )}
                {company.useElectronicInvoice && company.electronicInvoiceEmail && (
                    <InformationItem title={t('electronicInvoiceEmail')}>
                        <p>{company.electronicInvoiceEmail}</p>
                        {company.electronicInvoiceType && (
                            <div className="text-text-light">
                                <div className="text-text-default text-base">{t('electronicInvoiceType')}</div>
                                <p>{company.electronicInvoiceType}</p>
                            </div>
                        )}
                    </InformationItem>
                )}
                {company.website && (
                    <InformationItem title={t('website', 'Website')}>
                        <p>{company.website}</p>
                    </InformationItem>
                )}
                {company.pecEmail && (
                    <InformationItem title={t('pecEmail')}>
                        <p>{company.pecEmail}</p>
                    </InformationItem>
                )}
                {company.receiverCode && (
                    <InformationItem title={t('receiverCode', 'Receiver Code')}>
                        <p>{company.receiverCode}</p>
                    </InformationItem>
                )}
            </div>
        </Information>
    );
};
